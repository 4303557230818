<template>
  <AppViewport class="App" />
</template>

<script>
import store from '@/store';

import wrapVuexModule from '@/modules/games-shared/wrapVuexModule';

import systemModule from '@/modules/systems-shared/system-module';
import AppViewport from '@/modules/systems-shared/components/AppViewport';

import generateConfig from './config';

export default {
    components: { AppViewport },
    provide() {
        return {
            config: this.config,
            module: this.module
        };
    },
    beforeCreate() {
        this.config = generateConfig({ language: this.$store.getters['moduleApp/locale'] });

        if (!store.state.systemRhyme) {
            store.registerModule(['systemRhyme'], systemModule);
        }
        this.module = wrapVuexModule(store, 'systemRhyme');
        this.module.dispatch('load', this.config);
    },
    beforeDestroy() {
        store.unregisterModule(['systemRhyme']);
    }
};
</script>

import keyBy from 'lodash-es/keyBy';
import chunk from 'lodash-es/chunk';
import last from 'lodash-es/last';
import Item from './components/Item';
import MemorizeItem from './components/MemorizeItem';
import PracticeConfiguration from './components/PracticeConfiguration';
import backgroundImage from './images/background.png';

const images = require.context('./images/items', true, /\.(png|jpg)$/);

function getImageUrl(name) {
    try {
        return images(`./${name}.jpg`);
    } catch (err) {
        return null;
    }
}

const itemsRaw = {
    en: [
        [1, 'sun'],
        [2, 'shoe'],
        [3, 'tree'],
        [4, 'door'],
        [5, 'dive (scuba)'],
        [6, 'sticks (drum)'],
        [7, 'heaven'],
        [8, 'bait (fishing)'],
        [9, 'wine'],
        [10, 'hen'],
        [11, 'seven-eleven'],
        [12, 'shelf'],
        [13, 'tight jeans'],
        [14, 'farting'],
        [15, '50 Cent (rapper) '],
        [16, 'sunscreen'],
        [17, 'silver screen (a white or silvered surface where pictures can be projected for viewing)'],
        [18, 'racing'],
        [19, 'fine green (golf course)'],
        [20, 'penny']
    ],
    no: [
        [1, 'ben'],
        [2, 'do'],
        [3, 'tre'],
        [4, 'slire'],
        [5, 'hjem'],
        [6, 'heks'],
        [7, 'tyv'],
        [8, 'rotte'],
        [9, 'ski'],
        [10, 'politi'],
        [11, 'elven'],
        [12, 'troll'],
        [13, 'hetten'],
        [14, 'skjorten'],
        [15, 'skremten (spøkelse)'],
        [16, 'heisen'],
        [17, 'nøtten'],
        [18, 'hatten'],
        [19, 'dritten'],
        [20, 'flue']
    ]
};

export default function generateConfig({ language }) {
    const items = itemsRaw[language].map(([number, word]) => {
        const suffix = language === 'en' ? '-en' : '';
        return {
            id: `${number}${suffix}`,
            word,
            label: `${number}`,
            image: getImageUrl(`${number}${suffix}`)
        };
    });
    const itemsMap = keyBy(items, item => item.id);
    return {
        items: itemsMap,
        itemIds: items.map(item => item.id),
        itemGroups: chunk(items, 7).map((sublist, index) => {
            return {
                index,
                label: `${sublist[0].label}-${last(sublist).label}`,
                itemIds: sublist.map(x => x.id)
            };
        }),
        BrowseItem: Item,
        MemorizeItem,
        PracticeConfiguration,
        backgroundImage,
        systemId: 'rhyme',

        getImagesInGroup(group) {
            return group.itemIds.map(itemId => itemsMap[itemId].image);
        }
    };
}
